import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
// import 'primeflex/primeflex.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import './services';

import { Provider } from 'react-redux';
import { ConfigProvider } from './contexts/ConfigContext';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
// import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import Tailwind from 'primereact/passthrough/tailwind';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

const container = document.getElementById('root');
const root = createRoot(container);
const value = {
  appendTo: 'self',
};
root.render(
  <Provider store={store}>
    <ConfigProvider>
    <PrimeReactProvider value={{ unstyled: false }}>
      <App />
    </PrimeReactProvider>
    </ConfigProvider>
  </Provider>
);

reportWebVitals();
