import axios from "axios";
import EventBus from './event'
import FileSaver from 'file-saver';
//console.log('access_token', localStorage.getItem('access_token'))

// https://www.huanlelink.com/ 正式
// https://www.pickpay.info/back 正式
//export const rootPath = window.location.protocol+"//"+window.location.host+'/back';
export const rootPath = "https://www.yingweidapay.com/back";
//export const rootPath = "http://localhost:8000/back";
//export const rootPath = "https://www.pickpay.info/back";
// export const rootPath = "https://www.lepay.info/back";
// http://pickpay.info:8081 测试
export const instance = axios.create({
    baseURL: rootPath, // 设置统一的请求域名路径
    headers: {
        'Content-Type': 'application/json', // 设置请求头的 Content-Type
    }
});
export const open_instance = axios.create({
    baseURL: rootPath, // 设置统一的请求域名路径
    headers: {
        'Content-Type': 'application/json', // 设置请求头的 Content-Type
    }
});
export const exportOrder = async (path,request) => {
    const config = {
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
      }
    };
    axios.post(rootPath + path, request, config)
      .then(response => {
        // 从响应中获取 Excel 文件的 Blob 数据
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // 使用 file-saver 库保存 Blob 数据为文件
        FileSaver.saveAs(blob, 'exported_excel.xlsx');
      })
      .catch(error => {
        console.error('导出 Excel 文件时发生错误:', error);
        EventBus.emit('global_error_tips', '未找到相关数据')
      });
    //apiExportTOrderInfoList(getRequestData)
  }
let isRefresh = true;
const refreshTokenFun = async (status) => {
    if (status === 401) {
        const refresh_token = localStorage.getItem('refresh_token');
        if (refresh_token&&isRefresh) {
            isRefresh = false;
            const data = {
                body: {
                    refreshToken: refresh_token
                },
                extend: {},
                requestCode: 0
            }
            try {
                const res = await axios.post(rootPath + '/user/refresh/token', data);
                isRefresh = true;
                if (res.data.success) {
                    const datas = JSON.parse(res.data.data)
                    localStorage.setItem('access_token', datas.access_token)
                    localStorage.setItem('refresh_token', datas.refresh_token)
                    return datas.access_token; // 返回 undefined 或者其他值
                } else {
                    loginPage();
                }
            } catch (error) {
                loginPage();
            }
        }else{
            setTimeout(() => {
                if(!localStorage.getItem('refresh_token')){
                    loginPage();
                }
              }, 3000);
        }
    }
};


const loginPage = () => {
    // 将页面直接跳转到  /login
    localStorage.setItem('access_token', '')
    window.location.href = '/login'
    EventBus.emit('global_not_login', '登陆失效,请重新登陆')
    return Promise.reject('没有登录状态')
}

instance.interceptors.request.use(config => {
    console.log('Request Interceptor Executed:', config);
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// 中间件
open_instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('axios中间件', response)
    console.log('中间件', response)
    if (response.status === 200) {
        if (response.data.status !== undefined && response.data.status === 20000) {
            return response.data
        }
        // 全局的错误处理
        if (response.data.code !== 0 && response.data.code !== 401) {
            EventBus.emit('global_error_tips', response.data.message)
            console.log('发生了错误1')
        }
    } else {
        EventBus.emit('global_error_tips', response.data.message)
        console.log('发生了错误2')
    }
    return response;
}, function (error) {
    console.log('发生错误3', error)
    if (error.response.status == 401) {
        (async () => {
            const token = await refreshTokenFun(error.response.status);
            if (token) {
                window.location.reload();
            }
            //return instance.request(originalRequestConfig);
        })();
    }
    EventBus.emit('global_error_tips', error.response.data.message)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('axios中间件', response)
    console.log('中间件', response)
    if (response.status === 200) {
        if (response.data.status !== undefined && response.data.status === 20000) {
            return response.data
        }
        // 全局的错误处理
        if (response.data.code !== 0 && response.data.code !== 401) {
            EventBus.emit('global_error_tips', response.data.message)
            console.log('发生了错误1')
        }
    } else {
        EventBus.emit('global_error_tips', response.data.message)
        console.log('发生了错误2')
    }
    return response;
}, function (error) {
    console.log('发生错误3', error)
    if (error.response.status == 401) {
        (async () => {
            const token = await refreshTokenFun(error.response.status);
            if (token) {
                window.location.reload();
            }
            //return instance.request(originalRequestConfig);
        })();
    }
    EventBus.emit('global_error_tips', error.response.data.message)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default instance
