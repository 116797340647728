import axios from '../util/http'
export const apiGetTransactionDataAsync = (action) => {
    return axios.post(`/t/order/info/total/mch`, action);
}

/**
 * @param {*} 首页数据 
 * @returns 
 */
export const apiGetHomeData = (action) => {
    return axios.post(`/t/home/data/get`, action)
}
/**
 * @param {*} 首页弹框 
 * @returns 
 */
export const apiGetHomeAlartData = (action) => {
    return axios.post(`/open/system/notification/info`, action);
}
/**
 * @param {*} 提现数据汇总 
 * @returns 
 */
export const apiGetAllWithdrawal = (action) => {
    return axios.post(`/t/draw/record/total/mch`, action);
}

/**
 * @param {*} 筛选框列表查询 
 * @returns 
 */
export const apiGetScreenList = (action) => {
    return axios.post(`/t/sys/dictionary/all`, action);
}

/**
 * @param {*} 筛选下拉框获取
 * @returns 
 */
export const apiGetField = (action) => {
    return axios.post(`/oauth/sys/search/field/get`, action);
}

/**
 * @param {*} 获取支付类型 
 * @returns 
 */
export const apiGetPayType = (action) => {
    return axios.post(`/t/pay/type/config/list`, action);
}

/**
 * @param {*} 获取平台通知信息 
 * @returns 
 */
export const apiGetNotification = (action) => {
    return axios.post(`/open/system/notification/info`, action);
}

/**
 * @param {*}  获取订单列表
 * @returns 
 */
export const apiGetOrderInfoList = (action) => {
    return axios.post(`/t/order/info/list`, action);
}

/**
 * @param {*}  获取提现列表
 * @returns 
 */
export const apiGetWithdrawalist = (action) => {
    return axios.post(`/t/draw/record/list`, action);
}

/**
 * @param {*}  单笔/USDT提现
 * @returns 
 */
export const apiWithdrawal = (action) => {
    return axios.post(`/t/draw/record/add`, action);
}

/**
 * @param {*}  货币列表
 * @returns 
 */
export const apiAgentInfoList = (action) => {
    return axios.post(`/t/mch/agent/acc/info/list`, action);
}

/**
 * @param {*}  查询提现货币费率
 * @returns 
 */
export const apiQueryCurrencyRate = (action) => {
    return axios.post(`/t/mch/agent/config/detail`, action);
}

/**
 * @param {*}  查询商户账变
 * @returns
 */
export const apiQueryMerchantList = (action) => {
    return axios.post(`/t/mch/money/change/record/list`, action);
}

/**
 * @param {*}  修改登录密码
 * @returns
 */
export const apiChangeLoginPwd = (action) => {
    return axios.post(`/user/update/login/psw`, action);
}
export const apiChangeWithDrawPwd = (action) => {
    return axios.post(`/user/update/withdraw/psw`, action);
}
export const apiCancelBindGooglePsw = (action) => {
    return axios.post(`/user/cancel/bind/google/psw`, action);
}
/**
 * @param {*}  获取商户信息
 * @returns
 */
export const apiMchConfigDetail = (action) => {
    return axios.post(`/t/mch/config/detail`, action);
}
/**
 * @param {*}  获取商户支付列表
 * @returns
 */
export const apiMchPayConfigList = (action) => {
    return axios.post(`/t/mch/pay/config/list`, action);
}
/**
 * @param {*}  导出
 * @returns
 */
export const apiExportTOrderInfoList = (action) => {
    return axios.post(`/t/order/info/export`, action);
}
