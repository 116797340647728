const menuItems = {
  items: [
    {
      id: 'navigation',
      title: '导航菜单',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: '首页',
          type: 'item',
          icon: 'feather icon-home',
          url: '/app/dashboard/default'
        },
        {
          id: 'order',
          title: '交易记录',
          type: 'collapse',
          icon: 'pi pi-shopping-cart',
          url: '/basic/button',
          children: [
            {
              id: 'button',
              icon: "pi pi-database",
              url: "/order-pool",
              title: "数据汇总",
              type: 'item',
            },
            {
              id: 'button',
              icon: "pi pi-database",
              url: "/order-detail",
              title: "订单详情",
              type: 'item',
            },
          ]
        },
        {
          id: 'withdraw',
          title: '提现记录',
          type: 'collapse',
          icon: 'pi pi-dollar',
          url: '/basic/button',
          children: [
            {
              id: 'button',
              icon: "pi pi-database",
              url: "/withdraw-pool",
              title: "提现汇总",
              type: 'item',
            },
            {
              id: 'button',
              icon: "pi pi-database",
              url: "/withdraw-detail",
              title: "提现查询",
              type: 'item',
            },
          ]
        },
        {
          id: 'money',
          title: '资金管理',
          type: 'collapse',
          icon: 'pi pi-wallet',
          // url: '/basic/button',
          children: [
            {
              id: 'apply',
              icon: "pi pi-pound",
              title: "结算申请",
              type: 'collapse',
              children: [
                {
                  icon: "pi pi-file-edit",
                  url: "/withdraw-apply",
                  title: "单笔提现",
                  type: 'item',
                },
                {
                  icon: "pi pi-file-edit",
                  url: "/usdt-apply",
                  title: "USDT申请",
                  type: 'item',
                },
              ],
            },
            {
              id: 'money-detail',
              icon: "pi pi-wallet",
              title: "资金变动",
              type: 'collapse',
              children: [
                {
                  icon: "pi pi-file-edit",
                  url: "/money-detail-mch",
                  title: "商户账变",
                  type: 'item',
                },
                // {
                //   icon: "",
                //   pathname: "/money-detail-channel",
                //   title: "通道账变",
                // },
              ],
            },
          ]
        },
        {
          icon: "pi pi-book",
          title: "对接文档",
          type: 'collapse',
          children: [
            {
              icon: "pi pi-file-word",
              url: "/document-html",
              title: "在线文档",
              type: 'item',
            },
            {
              icon: "pi pi-file-word",
              url: "/document-html-download",
              title: "下载文档",
              type: 'item',
            },
          ],
        },
        {
          icon: "pi pi-cog",
          url: "/settings",
          title: "设置",
          type: 'item',
        },
      ]
    },
  ]
};

export default menuItems;
